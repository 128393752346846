import { PageContainer } from "../CommonStyles";
import logo from "../images/homeScreenLogo.jpg";
import address from "../images/address.jpg";
import instaIcon from "../images/instagram-icon.png";
import facebookIcon from "../images/facebook-icon.png";
import emailIcon from "../images/email-icon.png";
import phoneIcon from "../images/phone-icon.png";

const HomePage = () => {
    return (
        <PageContainer id="home">
            <div id="construction">
                <h1>Site is Currently Under Construction</h1>
                <p>For information and to look at what I do take a look at my <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Finstagram.com%2Fdream_weaver_dreadz%3Figshid%3DMjEwN2IyYWYwYw%253D%253D%26fbclid%3DIwAR1359SyyV6RfhzfY19lQQdiield_qPChPK_xTUWyYs3Dg5QwyhlgQoXvaA&h=AT2khQ4sNSTKUYIX7GcinA98KGvTr4b4FZalnBkmr0Mo8vXmTEDlSTULVwFuTsHYom7jt1YPfw7a3UB9z-oFEgZkGdNSZG3tcvqMCWPNSa_W2bJTytpYCaY6cY0h6nAA_3IIx-2hHvZnWY-gdZM" rel="noreferrer">Instagram</a></p>
            </div>
            <img src={logo} alt="Dream Weaver text" id="dream" loading="lazy" />
            <h2>Dreadlock maker, weaver and healer</h2>
            <div id="bottom-sec">
                <p>Permanent installation & maintenance of dreadlocks. Based in Northwich, Cheshire.</p>
                <div id="address-sec">
                    <img src={address} alt="address" id="map" loading="lazy"/>
                </div>
                <div id="contact-sec">
                    <h1 className="alt-text-color">Contact Me</h1>
                    <div id="contact-main">
                        <div className="contact-line">
                            <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Finstagram.com%2Fdream_weaver_dreadz%3Figshid%3DMjEwN2IyYWYwYw%253D%253D%26fbclid%3DIwAR1359SyyV6RfhzfY19lQQdiield_qPChPK_xTUWyYs3Dg5QwyhlgQoXvaA&h=AT2khQ4sNSTKUYIX7GcinA98KGvTr4b4FZalnBkmr0Mo8vXmTEDlSTULVwFuTsHYom7jt1YPfw7a3UB9z-oFEgZkGdNSZG3tcvqMCWPNSa_W2bJTytpYCaY6cY0h6nAA_3IIx-2hHvZnWY-gdZM" target="_blank" rel="noreferrer"><img src={instaIcon} alt="Instagram Logo" id="insta" loading="lazy"/></a>
                            <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Finstagram.com%2Fdream_weaver_dreadz%3Figshid%3DMjEwN2IyYWYwYw%253D%253D%26fbclid%3DIwAR1359SyyV6RfhzfY19lQQdiield_qPChPK_xTUWyYs3Dg5QwyhlgQoXvaA&h=AT2khQ4sNSTKUYIX7GcinA98KGvTr4b4FZalnBkmr0Mo8vXmTEDlSTULVwFuTsHYom7jt1YPfw7a3UB9z-oFEgZkGdNSZG3tcvqMCWPNSa_W2bJTytpYCaY6cY0h6nAA_3IIx-2hHvZnWY-gdZM" target="_blank" rel="noreferrer"><p className="link">dream_weaver_dreadz</p></a>
                        </div>
                        <div className="contact-line">
                            <a href="https://www.facebook.com/profile.php?id=100089441582493" target="_blank" rel="noreferrer"><img src={facebookIcon} alt="Instagram Logo" id="insta" loading="lazy" /></a>
                            <a href="https://www.facebook.com/profile.php?id=100089441582493" target="_blank" rel="noreferrer"><p className="link">Dream Weaver</p></a>
                        </div>
                        <div className="contact-line">
                            <img src={emailIcon} alt="email icon" loading="lazy"/>
                            <p>dreamweaverdreadz@gmail.com</p>
                        </div>
                        <div className="contact-line">
                            <img src={phoneIcon} alt="phone icon" loading="lazy" />
                            <p>07368287180</p>
                        </div>
                    </div>
                </div>
            </div>
        </PageContainer>
    )
}
export default HomePage;