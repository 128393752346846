import styled from "styled-components";

export const PageContainer = styled.div`
    min-height: calc(100vh - var(--navbar-height) - var(--footer-height));
    padding: 20px 0;
    max-width: 95%;
    width: 1000px;
    margin: auto;
    h1, h2, p{
        margin: 0;
        text-align: center;
    }
    .alt-text-color{
        color: var(--secondary-color);
    }

    #construction{
        margin-bottom: 50px;
    }
    
    &&#home{
        #dream{
            margin: auto;
            max-width: 90%;
            width: 250px;
        }
        #bottom-sec{
            margin-top: 20px;
            > p{
                color: var(--secondary-color);
                font-size: 20px;
            }
            #address-sec{
                display: flex;
                justify-content: center;
                #map{
                    max-width: 90%;
                    width: 300px;
                }
            }
            #contact-sec{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                margin: 20px 0;
                .contact-line{
                    display: flex;
                    align-items: center;
                    img{
                        max-width: 32px;
                        max-height: 32px;
                        margin: 0 10px;
                    }
                    a{
                        text-decoration: none;
                    }
                    p{
                        margin: 0;
                        color: var(--font-color);
                    }
                }
            }
        }
    }
    
    &&#about{
        h2{
            margin-bottom: 15px;
        }
        #images{
            display: flex;
            justify-content: space-between;
            max-width: 500px;
            margin: 30px auto;

            img{
                max-width: 50%;
                width: 200px;
            }
        }
    }
    
    &&#faq{
        > div{
            h2{
                margin-bottom: 5px;
            }
            margin: 50px auto;
            max-width: 500px;
        }
    }

    &&#pricelist{
        h1{
            margin-bottom: 20px;
        }
        > ul{
            max-width: 500px;
            margin: 20px auto;
        }
        > p{
            max-width: 500px;
            margin: auto;
        }
        /* max-width: 550px; */
    }
`