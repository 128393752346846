import { PageContainer } from "../CommonStyles";

const FaqPage = () => {
    return (
        <PageContainer id="faq">
            <h1 className="alt-text-color">FAQ</h1>
            <div>
                <h2 className="alt-text-color">Can you wash dreadlocks?</h2>
                <p>Yes! You can wash them every 1-2 weeks! An added bonus is that dreadlocks protect the natural oils in your hair. It is recommended that you use an all-natural shampoo.</p>
            </div>
            <div>
                <h2 className="alt-text-color">How often should I get maintenance?</h2>
                <p>It's entirely up to you! On average every 6 months but I have one client who has it done every 3 months and another who chooses to have it every few years.</p>
            </div>
            <div>
                <h2 className="alt-text-color">How long does my hair need to be to get dreadlocks?</h2>
                <p>Your hair needs to be 5 - 6 inches long.</p>
            </div>
            <div>
                <h2 className="alt-text-color">Do you add extensions? </h2>
                <p>Yes! I use human hair to make the extensions and try to get as close a match in colour to your own hair.</p>
            </div>
        </PageContainer>
    )
}
export default FaqPage;