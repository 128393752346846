import './App.css';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import HomePage from './pages/HomePage';
import Navbar from './components/Navbar';
import AboutPage from './pages/AboutPage';
import GalleryPage from './pages/GalleryPage';
import FaqPage from './pages/FaqPage';
import PriceListPage from './pages/PriceListPage';
// import Footer from './components/Footer';

function App() {
  return (
    <BrowserRouter>
      <Navbar/>
      <Routes>
        <Route path="/" element={<HomePage/>}/>
        <Route path="/about" element={<AboutPage/>}/>
        <Route path="/gallery" element={<GalleryPage/>}/>
        <Route path="/faq" element={<FaqPage/>}/>
        <Route path="/pricelist" element={<PriceListPage/>}/>
      </Routes>
      {/* <Footer/> */}
    </BrowserRouter>
  );
}

export default App;