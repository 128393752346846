import { PageContainer } from "../CommonStyles";

const PriceListPage = () => {
    return (
        <PageContainer id="pricelist">
            <h1 className="alt-text-color">Price List</h1>
            <h2 className="alt-text-color">Maintenance</h2>
            <ul>
                <li>1 hour is £20</li>
                <li>2 hour is £35</li>
                <li>3 hour is £45</li>
                <li>4 hour is £55 etc etc</li>
                <p>I require a £20 deposit to secure the booking</p>
            </ul>
            <h2 className="alt-text-color">To Dread Your Hair - Consultation Required</h2>
            <ul>
                <li>Undercuts from £50 (cost will depend on hair length)</li>
                <li>Peek-A-Boos £75</li>
                <li>Shoulder Length £250</li>
                <li>Mid-back length £300</li>
                <li>Long Length from £400 (cost will depend on hair length)</li>
                <p>I require a £100 deposit to secure the booking</p>
            </ul>
            <h2 className="alt-text-color">To Add Dread Extensions - Consultation Required</h2>
            <ul>
                <li>Undercuts and Shoulder Length £450</li>
                <li>Mid-back length £500</li>
                <li>Long Length £600</li>
            </ul>
            <p>These prices include the cost of real human hair, the time it takes to dread the extensions, dreading your own hair and attaching the extensions.<br/>I require a £200 deposit to secure the booking and to cover the cost of the hair.</p>
        </PageContainer>
    )
}
export default PriceListPage;