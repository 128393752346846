import { PageContainer } from "../CommonStyles";
import aboutMe from "../images/aboutMe.jpg";
import teaByATree from "../images/teaByATree.jpg";

const AboutPage = () => {
    return (
        <PageContainer id="about">
            <h1 className="alt-text-color">About Me</h1>
            <div id="images">
                <img src={teaByATree} alt="Drinking tea by a tree" loading="lazy"/>
                <img src={aboutMe} alt="me" loading="lazy" />
            </div>
            <h2>Dreadlocks Are Awesome!</h2>
            <p>I have always thought so which us why it was a life goal to become a loctician.</p>
        </PageContainer>
    )
}
export default AboutPage;