import styled from "styled-components";
import { PageContainer } from "../CommonStyles";
import RikMain from "../images/gallery/RikMaintenance/main.jpg";
import RikBeforeAndAfter from "../images/gallery/RikMaintenance/BeforeAndAfter.jpg";
import LeonMain from "../images/gallery/LeonMaintenance/main.jpg";
import LeonBeforeAndAfter from "../images/gallery/LeonMaintenance/BeforeAndAfter.jpg";

const GalleryPage = () => {
    return (
        <PageContainer id="gallery">
            <h1 className="alt-text-color">Gallery</h1>
            <GalleryContainer>
                <div className="individual">
                    <img src={RikMain} alt="Rik's main" loading="lazy" />
                    <img src={RikBeforeAndAfter} alt="Rik's before and after" loading="lazy"/>
                </div>
                <div className="individual">
                    <img src={LeonMain} alt="Leon's main" loading="lazy" />
                    <img src={LeonBeforeAndAfter} alt="Leon's before and after" loading="lazy" />
                </div>
            </GalleryContainer>
        </PageContainer>
    )
}

const GalleryContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
    gap: 20px;
    .individual{
        display: flex;
        gap: 10px;
    }
    img{
        border: none;
        border: 1px solid white;
        height: 300px;
        width: 300px;
    }
`

export default GalleryPage;