import styled from "styled-components";
import { NavLink as Link } from "react-router-dom";
import LogoImage from "../images/logo.png";

const Navbar = () => {
    return(
        <NavbarContainer>
            <Logo src={LogoImage} alt="logo"/>
            <div>
                <NavLink to="/">Home</NavLink>
                <NavLink to="/gallery">Gallery</NavLink>
                <NavLink to="/pricelist">Price List</NavLink>
                <NavLink to="/about">About</NavLink>
                <NavLink to="/faq">FAQ</NavLink>
            </div>
        </NavbarContainer>
    )
}

const NavbarContainer = styled.nav`
    min-height: var(--navbar-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: var(--accent-color); */
    /* margin: 10px 0px 0px; */
`

const NavLink = styled(Link)`
    margin: 0 10px;
    text-decoration: none;
    color: var(--secondary-color);
    :hover{
        text-decoration: underline;
    }
`

const Logo = styled.img`
    max-width: 100px;
`

export default Navbar;